@import 'scss/user/_fonts';
@import 'swiper/swiper';
@import 'swiper/swiper-bundle';
@import 'animate.css/animate';
@import 'scss/style.default';

@include media-breakpoint-down('sm') {
  .navbar-toggler:focus {
    box-shadow: none;
    outline: none;
  }
  .fs-navbar-collapse {
    height: 100vh;
    max-height: none !important;
    transition: transform 5s cubic-bezier(0.3, 0, 0.7, 1), opacity 1s ease;
    &.show {
      opacity: 1;
      visibility: visible;
      -webkit-animation-name: kenBurns;
      animation-name: kenBurns;
    }
    &:not(.show) {
      opacity: 0;
      visibility: hidden;
    }
    .megamenu.shadow {
      box-shadow: none !important;
    }

    form .dropdown-menu {
      transform: translate3d(0px, 40px, 10px) !important;
      width: calc(100vw - 33px) !important;
      height: 100vh !important;
      max-height: none !important;
    }
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    height: 65px;
  }
}

@include media-breakpoint-down('md') {
  .navbar-logo {
    position: absolute;
    top: 0;
    height: 65px;
  }
}

.navbar-logo {
  position: absolute;
  top: 0;
}

.bg-blue {
  background-color: $gold;
}
